<template>
  <span
    class="inline-flex size-8 items-center justify-center rounded-full bg-neutral-200 dark:bg-neutral-600"
  >
    <Icon
      icon="OfficeBuilding"
      class="size-5 text-neutral-400 dark:text-neutral-300"
    />
  </span>
</template>
