<template>
    <BaseFormField
        v-slot="{ isReadonly, fieldId }"
        :resource-name="resourceName"
        :field="field"
        :value="model"
        :is-floating="isFloating"
    >
        <FormFieldGroup
            :field="field"
            :label="field.label"
            :field-id="fieldId"
            :validation-errors="validationErrors"
        >
            <IFormTextarea
                v-if="!field?.customField?.is_html"
                :id="fieldId"
                v-model="model"
                :placeholder="field?.customField?.metadata?.placeholder || ''"
                :disabled="isReadonly"
                :name="field.attribute"
                v-bind="field.attributes"
            />
            <Editor
                :id="fieldId"
                v-if="!!field?.customField?.is_html"
                v-model="model"
                :disabled="isReadonly"
                :placeholder="field?.customField?.metadata?.placeholder || ''"
                :name="field.attribute"
                v-bind="field.attributes"
            />
        </FormFieldGroup>
    </BaseFormField>
</template>

<script setup>
import isNil from 'lodash/isNil'

import FormFieldGroup from '../FormFieldGroup.vue'

const props = defineProps({
    field: { type: Object, required: true },
    resourceName: String,
    resourceId: [String, Number],
    validationErrors: Object,
    isFloating: Boolean,
})

const emit = defineEmits(['setInitialValue'])

const model = defineModel()

function setInitialValue() {
    emit('setInitialValue', !isNil(props.field.value) ? props.field.value : '')
}

setInitialValue()
</script>
