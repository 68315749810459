<template>
  <div ref="wrapperRef">
    <div id="accordion-open" data-accordion="open">
      <div class="grid grid-cols-12 gap-x-4">
        <div
          v-for="field in iterableFields"
          :key="field.attribute"
          :class="
            field.width === 'half' ? 'col-span-12 sm:col-span-6' : 'col-span-12'
          "
        >
          <slot :name="`before-${field.attribute}-field`" :field="field"></slot>

          <h2
            v-if="field.component === 'section'"
            :id="`accordion-open-heading-${field.attribute}`"
          >
            <button
              type="button"
              class="flex w-full items-center justify-between gap-3 border border-gray-200 p-5 font-medium text-gray-500 hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-gray-800 rtl:text-right"
              :data-accordion-target="`#accordion-open-body-${field.attribute}`"
              aria-expanded="true"
              :aria-controls="`accordion-open-body-${field.attribute}`"
            >
              <span class="flex items-center">
                {{ field.label }}
              </span>
              <svg
                data-accordion-icon
                class="h-3 w-3 shrink-0 rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5 5 1 1 5"
                />
              </svg>
            </button>
          </h2>
          <div
            :id="`accordion-open-body-${field.attribute}`"
            class="hidden"
            :aria-labelledby="`accordion-open-heading-${field.attribute}`"
            v-if="field.component === 'section'"
          >
            <div
              class="border border-t-0 border-gray-200 p-5 dark:border-gray-700"
            >
              <div v-for="f in field.section">
                <div
                  :class="
              f.displayNone ||
              f.hidden ||
              (collapsed && f.collapsed)
                ? 'hidden'
                : ''
            "

                >
                  <component
                    :is="f.formComponent"
                    :field="f"
                    :resource-id="resourceId"
                    :resource-name="resourceName"
                    :is-floating="isFloating"
                    :validation-errors="groupedValidationErrors[f.attribute]"
                    :model-value="form[f.attribute]"
                    @update:model-value="
                $emit('updateFieldValue', {
                  attribute: f.attribute,
                  value: $event,
                })
              "
                    @set-initial-value="
                $emit('setInitialValue', {
                  attribute: f.attribute,
                  value: $event,
                })
              "
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="field.component !== 'section'"
            :class="
              field.displayNone ||
              field.hidden ||
              (collapsed && field.collapsed)
                ? 'hidden'
                : ''
            "

          >
            <component
              :is="field.formComponent"
              :field="field"
              :resource-id="resourceId"
              :resource-name="resourceName"
              :is-floating="isFloating"
              :validation-errors="groupedValidationErrors[field.attribute]"
              :model-value="form[field.attribute]"
              @update:model-value="
                $emit('updateFieldValue', {
                  attribute: field.attribute,
                  value: $event,
                })
              "
              @set-initial-value="
                $emit('setInitialValue', {
                  attribute: field.attribute,
                  value: $event,
                })
              "
            />
          </div>
          <slot :name="`after-${field.attribute}-field`" :field="field"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUpdated, ref } from 'vue'
import { useTimeoutFn } from '@vueuse/core'
import castArray from 'lodash/castArray'
import { initFlowbite } from 'flowbite'

const props = defineProps({
  fields: { required: true, type: Array },
  form: { required: true, type: Object },

  collapsed: Boolean,

  resourceId: [String, Number],
  resourceName: String,

  except: [Array, String],
  only: [Array, String],

  isFloating: Boolean,
  focusFirst: Boolean,
})

defineEmits(['updateFieldValue', 'setInitialValue'])

const wrapperRef = ref(null)

const only = computed(() => (props.only ? castArray(props.only) : []))
const except = computed(() => (props.except ? castArray(props.except) : []))

const iterableFields = computed(() => {
  if (!props.fields) {
    return []
  }

  let fields = []
  Object.values(props.fields).forEach(field => {
    if (field.customField?.hint) {
      field.helpText = field.customField.hint
    }

    if (field.customField?.section) {
      let added = false
      fields.every((element, index) => {
        if (
          element?.component == 'section' &&
          element?.attribute == field.customField.section.toCamelCase()
        ) {
          element.section.push(field)
          added = true
          return false
        }
        return true
      })
      if (!added) {
        fields.push({
          component: 'section',
          attribute: field.customField.section.toCamelCase(),
          label: field.customField.section,
          section: [field],
          width: "full"
        })
      }
    } else {
      fields.push(Object.assign({}, field))
    }
    if (field.customField?.metadata) {
      try {
        field.customField.metadata = JSON.parse(field.customField.metadata)
      } catch (error) {}
    }
  })
  if (props.only) {
    return fields.filter(
      field => only.value.indexOf(field.attribute) > -1
    )
  } else if (props.except) {
    return fields.filter(
      field => except.value.indexOf(field.attribute) === -1
    )
  }
  return fields
})

const groupedValidationErrors = computed(() => props.form.errors.groupByField())

function focusToFirstFocusableElement() {
  const focusAbleInputs = [
    'date',
    'datetime-local',
    'email',
    'file',
    'month',
    'number',
    'password',
    'range',
    'search',
    'tel',
    'text',
    'time',
    'url',
    'week',
  ]

  const input = wrapperRef.value.querySelector('div:first-child input')
  const textarea = wrapperRef.value.querySelector('div:first-child textarea')

  if (input && focusAbleInputs.indexOf(input.getAttribute('type')) > -1) {
    input.focus()
  } else if (textarea) {
    textarea.focus()
  }
}

if (props.focusFirst) {
  onMounted(() => {
    useTimeoutFn(focusToFirstFocusableElement, 600)
  })
} else {
  onMounted(() => {
  })
}

onUpdated(() => {
  initFlowbite()
})

</script>
