<template>
  <IFormGroup
    label-for="field_type"
    :label="$t('core::fields.custom.type')"
    required
  >
    <ICustomSelect
      :model-value="fieldType"
      :options="fieldsTypes"
      :clearable="false"
      :disabled="inEditMode"
      @update:model-value="$emit('update:fieldType', $event)"
      @option-selected="!label ? $refs.labelRef.focus() : ''"
    />

    <IFormError :error="form.getError('field_type')" />
  </IFormGroup>

  <IFormGroup label-for="label" :label="$t('core::fields.label')" required>
    <IFormInput
      id="label"
      ref="labelRef"
      :model-value="label"
      @update:model-value="$emit('update:label', $event)"
    />

    <IFormError :error="form.getError('label')" />
  </IFormGroup>

  <IFormGroup label-for="section" :label="$t('core::fields.section')">
    <IFormInput
      id="section"
      ref="sectionRef"
      :model-value="section"
      @update:model-value="$emit('update:section', $event)"
    />

    <IFormError :error="form.getError('section')" />
  </IFormGroup>

  <IFormGroup label-for="hint" :label="$t('core::fields.hint')">
    <IFormInput
      id="hint"
      ref="hintRef"
      :model-value="hint"
      @update:model-value="$emit('update:hint', $event)"
    />

    <IFormError :error="form.getError('hint')" />
  </IFormGroup>

  <IFormGroup label-for="external_ids" :label="$t('core::fields.external_ids')">
    <IFormInput
      id="external_ids"
      ref="externalIdsRef"
      :model-value="external_ids"
      @update:model-value="$emit('update:external_ids', $event)"
    />

    <IFormError :error="form.getError('external_ids')" />
  </IFormGroup>

  <IFormGroup label-for="default_value" :label="$t('core::fields.default_value')">
    <IFormTextarea
      id="default_value"
      rows="2"
      :model-value="default_value"
      @update:model-value="$emit('update:default_value', $event)"
    />
    <IFormError :error="form.getError('default_value')" />
  </IFormGroup>

  <IFormCheckboxField class="mb-2 gap-y-0">
    <IFormCheckbox
      :checked="is_html"
      :model-value="is_html"
      @change="$emit('update:is_html', $event)"
    />

    <IFormCheckboxLabel :text="$t('core::fields.is_html')" />
  </IFormCheckboxField>

  <FieldOptions
    v-if="isOptionableField"
    :options="options"
    :form="form"
    @update:options="$emit('update:options', $event)"
  />

  <IFormGroup class="mt-4" :description="$t('core::fields.custom.id_info')">
    <template #label>
      <div class="flex">
        <IFormLabel
          class="mb-1 grow"
          for="field_id"
          :label="$t('core::fields.custom.id')"
          required
        />

        <IButtonCopy
          v-show="fieldId"
          class="-mt-1.5 mb-0.5"
          :text="fieldId"
          :success-message="$t('core::app.copied')"
          small
          basic
        >
          {{ $t('core::app.copy') }}
        </IButtonCopy>
      </div>
    </template>

    <div class="relative">
      <ITextBlockDark
        v-if="!inEditMode"
        class="pointer-events-none absolute inset-y-0 flex items-center pl-3 opacity-80"
      >
        {{ idPrefix }}
      </ITextBlockDark>

      <IFormInput
        id="field_id"
        v-model="localFieldId"
        :disabled="inEditMode"
        :class="{ '!pl-8': !inEditMode }"
      />
    </div>

    <IFormError :error="form.getError('field_id')" />
  </IFormGroup>

  <IFormGroup label-for="metadata" :label="$t('core::fields.metadata')">
    <IFormTextarea
      id="metadata"
      rows="2"
      :model-value="metadata"
      @update:model-value="$emit('update:metadata', $event)"
    />
    <IFormError :error="form.getError('metadata')" />
  </IFormGroup>

  <IFormGroup v-if="isUniqueable">
    <IFormCheckboxField>
      <IFormCheckbox
        :checked="isUnique"
        :disabled="inEditMode && !isUnique && !isOriginallyUnique"
        @update:checked="$emit('update:isUnique', $event)"
      />

      <IFormCheckboxLabel :text="$t('core::fields.mark_as_unique')" />

      <IFormCheckboxDescription
        v-if="inEditMode && isUnique === false && isOriginallyUnique"
      >
        <span class="text-danger-600 dark:text-danger-500">
          {{ $t('core::fields.unmark_as_unique_change_info') }}
        </span>
      </IFormCheckboxDescription>

      <IFormCheckboxDescription
        v-else-if="!inEditMode || (inEditMode && !isUnique)"
        :text="$t('core::fields.mark_as_unique_change_info')"
      />
    </IFormCheckboxField>

    <IFormError :error="form.getError('is_unique')" />
  </IFormGroup>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { watchDebounced } from '@vueuse/core'
import find from 'lodash/find'
import map from 'lodash/map'

import { useApp } from '@/Core/composables/useApp'

import FieldOptions from './CustomFieldsFormOptions.vue'

const props = defineProps({
  form: { required: true, type: Object },
  fieldType: { required: true, type: String },
  label: { required: true, type: String },
  section: { required: false, type: String },
  hint: { required: false, type: String },
  external_ids: { required: false, type: String },
  default_value: { required: false, type: String },
  is_html: Boolean,
  metadata: { required: false, type: String },
  fieldId: { required: true, type: String },
  resourceName: { required: true, type: String },
  isUnique: { required: true },
  options: { required: true },
  inEditMode: Boolean,
})

const emit = defineEmits([
  'update:fieldType',
  'update:label',
  'update:section',
  'update:hint',
  'update:external_ids',
  'update:default_value',
  'update:is_html',
  'update:metadata',
  'update:fieldId',
  'update:isUnique',
  'update:options',
])

const { scriptConfig } = useApp()

const resources = Innoclapps.resources()
const customFields = scriptConfig('fields.custom_fields')
const idPrefix = scriptConfig('fields.custom_field_prefix')

const localFieldId = ref(props.fieldId || null)
const isOriginallyUnique = props.isUnique

const fieldsTypes = computed(() => map(customFields, 'type'))

const isOptionableField = computed(() => {
        let field = find(customFields, {
            type: props.fieldType,
            optionable: true,
        })
        return Boolean(field) && !["UserField", "ContactField", "CompanyField"].includes(field.type)
    }
)

const isUniqueable = computed(() => {
  return (
    Boolean(
      find(customFields, {
        type: props.fieldType,
        uniqueable: true,
      })
    ) &&
    Boolean(
      find(resources, {
        acceptsUniqueCustomFields: true,
        name: props.resourceName,
      })
    )
  )
})

watch(isUniqueable, newVal => {
  emit(
    'update:isUnique',
    newVal ? (props.isUnique !== null ? props.isUnique : false) : null
  )
})

watchDebounced(
  localFieldId,
  newVal => {
    if (!props.inEditMode) {
      emit('update:fieldId', newVal ? `${idPrefix}${newVal}` : null)
    }
  },
  { debounce: 250 }
)

watch(isOptionableField, (newVal, oldVal) => {
  if (!newVal && oldVal) {
    emit('update:options', null)
  } else if (newVal) {
    emit('update:options', [])
  }
})
</script>
